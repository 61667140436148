import { Icon, genrateURL } from '../Components/Assets';
import { WebsiteDevelopment } from './Services/WebsiteDevelopment';
import { GraphicDesign } from './Services/GraphicDesign';
import { SocialMediaMarketing } from './Services/SocialMediaMarketing';
import { SearchEngineOptimization } from './Services/SearchEngineOptimization';
import { PayPerClick } from './Services/PayPerClick';
import { ContentMarketing } from './Services/ContentMarketing';
export const WebSiteData = 
    {
      contact : [
        {
          heading: "Let’s talk",
          icon: Icon.call,
          items: [
            {
              title: "+91 989 694 6523",
              url: "tel:+919896946523"
            }
          ]
        },
        {
          heading: "Email",
          icon: Icon.mail,
          items: [
            {
              title: "info@codingnectar.com",
              icon: "Icon.mail",
              url: "mailto:info@codingnectar.com"
            },
            // {
            //   title: "Codingnectarteam@gmail.com",
            //   icon: "Icon.mail",
            //   url: "mailto:Codingnectarteam@gmail.com"
            // }
          ],
        },
        {
          heading: "Address",
          icon: Icon.location,
          items: [
            {
              title: "17FF, Housing Board Society, Sec 48, Gurugram, Haryana - 122001",
              url: "/",
            }
          ]
        }
      ],                      
      social: [
        // {
        //   title: "Facebook",
        //   icon: Icon.facebook,
        //   url : "https://xd.adobe.com/view/737851b6-ff7d-4262-bb69-4a015e0a629e-262b/specs/",
        // },
        {
          title: "Instagram",
          icon: Icon.instagram,
          url : "https://www.instagram.com/codingnectar/",
        },
        {
          title: "Twitter",
          icon: Icon.twitter,
          url : "https://x.com/CodingN284825",
        },
        {
          title: "LinkedIn",
          icon: Icon.linkedIn,
          url : "https://www.linkedin.com/in/coding-nectar-b57ba52aa/",
        }
      ],
      inquirypop:[
        {
          heading: "Website Development",
          content: "Website Design | Web Development | E-Commerce Solutions | UI/UX Design",
          // img: Icon.aboutIco1,
          icon: Icon.webDevelopment,
          // imgTag: "Website development company",
        },
        {
          heading: "Graphic Design",
          content: "Brand Identity | Illustration | User Interface Design | Brand Building",
          // img: Icon.aboutIco2,
          icon: Icon.GraphicIcon,
          // imgTag: "Website development company",
        },
        {
          heading: "Search Engine Optimization",
          content: "On-Page Optimization | Off-Page Optimization | Technical SEO | Analytics & Reporting",
          icon: Icon.SeoIcon,
          // imgTag: "Website development company",
        },
        {
          heading: "Social Media Marketing",
          content: "Facebook | Instagram | LinkedIn | YouTube",
          icon: Icon.SMMIcon,
          // imgTag: "Website development company",
        },
        {
          heading: "Pay Per Click",
          content: "Google Ads Management | Google Search Ads | Conversion Tracking & Optimization",
          icon: Icon.PPCIcon,
          // imgTag: "Website development company",
        },
        {
          heading: "Content Marketing",
          content: "SEO Content Writing | Website Content | Social Media Content | Blog Writing",
          icon: Icon.ContentMarketingIcon,
          // imgTag: "Website development company",
        }
      ],
      homeBanner: [
        {
          title: "Building Websites that Stand Out in the Crowd",
          description: "Where code meets artistry we develop it with a team of experts in web development and design. “Creating digital solutions that meet your needs",
          img: "webdevelpment.png",
          imgTag: "Website development company"
        },
        {
          title: "Unleashing the power of Visual Story telling",
          description: "Bringing ideas to life through design. Creating engaging visuals that capture attention. Design that resonates with your audience",
          img: "ban2.png",
          imgTag: "Website development company"
        },
        {
          title: "Climb the Higher Ranks with our SEO Expertise",
          description: "Boost your online presence with our expert SEO strategies. Drive more traffic, Increase conversions, and outrank the competition. Maximize you search engine potential",
          img: "BanSEO.png",
          imgTag: "Website development company"
        },
        {
          title: "Maximize your Social Media Potential, Maximize your Result",
          description: "Reach new audiences, build brand awareness, and drive website traffic. Our social media expertise helps you connect with customers. Get noticed, get remembered get results",
          img: "BanSMO.png",
          imgTag: "Website development company"
        },
        {
          title: "Amplify Your Reach-Expert PPC Service for More Leads and Sale",
          description: "Take control of your online advertising with our PPC services. Get maximum ROI, increase conversions and reduce costs. Expertly managed campaigns for long term success",
          img: "BanPayPerClick.png",
          imgTag: "Website development company"
        },
        {
          title: "Content that Drives Results, not just Words",
          description: "Content that speaks to your audience's needs. Expert writers crafting compelling, informative, and engaging content. 'Write your way to success'",
          img: "BanContentWrite.png",
          imgTag: "Website development company"
        },
      ],
      aboutCompany:[
        {
          title: "Web Development",
          content: "We blend aesthetics with functionality through our website design development services and deliver impactful digital experiences.",
          img: Icon.aboutIco1,
          imgTag: "Website development company",
        },
        {
          title: "Search Engine Optimization",
          content: "We implement our performing Search Engine Optimization (SEO) services to rank websites higher on search engines, organically.",
          img: Icon.aboutIco2,
          imgTag: "Website development company",
        },
        {
          title: "Brand Building",
          content: "Branding is way more than a logo and aesthetics. We assess and identify the opportunities to craft memorable brand identities.",
          img: Icon.aboutIco3,
          imgTag: "Website development company",
        },
        {
          title: "User Engagement",
          content: "We fill the gaps between brands and their audience with responsive marketing strategies to build trust and encourage interaction.",
          img: Icon.aboutIco4,
          imgTag: "Website development company",
        },
      ],
      whyChooseUs: [
        {
          value: 4,
          unit: '+',
          description: "Year of Dedication",
        },
        {
          value: 150,
          unit:'+',
          description: "Successful Projects",
        },
        {
          value: 99,
          unit: '%',
          description: "Delighted Clients",
        },
        {
          value: 20,
          unit: '+',
          description: "Skilled Team Members",
        }
      ],
      weServe: [
        {
          question: "Industries",
          answer: "From ambitious start-ups to thriving industry leaders, we offer Web Design and Development services for Single Vendors, Multi-vendors dealing in Education, NGO, Tour & Travel, E-commerce, Healthcare, Hospitality, Tourism, Real Estate, Food and Beverage, Fashion industries. Have a special project in mind? Let’s connect.",
          icons: [
            {
              name: "Education",
              img: Icon.education,
              imgTag: "Website development company",
            },
            {
              name: "Single Vendor",
              img: Icon.singleVendor,
              imgTag: "Website development company",
            },
            {
              name: "Multi-Vendor",
              img: Icon.multiVendor,
              imgTag: "Website development company",
            },
            {
              name: "Tour & Travel",
              img: Icon.tourTravel,
              imgTag: "Website development company",
            },
            {
              name: "Restaurant",
              img: Icon.restaurant,
              imgTag: "Website development company",
            },
            {
              name: "Real Estate",
              img: Icon.realEstate,
              imgTag: "Website development company",
            },
            {
              name: "Wellness",
              img: Icon.healthWellth,
              imgTag: "Website development company",
            },
            {
              name: "NGO",
              img: Icon.ngo,
              imgTag: "Website development company",
            },
            {
              name: "Branding",
              img: Icon.branding,
              imgTag: "Website development company",
            }
          ]
        },
        {
          question: "Technology",
          answer: "Highly-equipped Website Development team, coding in advanced technologies and delivering Shopify, Dukaan, Wix, React, Codelgniter, HTML, PHP, Laravel, Wordpress websites. Want a website with intuitive functionalities? Let’s connect.",
          icons: [
            {
              name: "HTML",
              img: Icon.html,
              imgTag: "Website development company",
            },
            {
              name: "PHP",
              img: Icon.php,
              imgTag: "Website development company",
            },
            {
              name: "Laravel",
              img: Icon.larvel,
              imgTag: "Website development company",
            },
            {
              name: "WordPress",
              img: Icon.wordpress,
              imgTag: "Website development company",
            },
            {
              name: "Shopify",
              img: Icon.shopify,
              imgTag: "Website development company",
            },
            {
              name: "Dukaan",
              img: Icon.dukaan,
              imgTag: "Website development company",
            },
            {
              name: "Wix",
              img: Icon.wix,
              imgTag: "Website development company",
            },
            {
              name: "React",
              img: Icon.react,
              imgTag: "Website development company",
            },
            {
              name: "CodeIgniter",
              img: Icon.codeigniter,
              imgTag: "Website development company",
            }
          ]
        },
        {
          question: "Services",
          answer: "360-Degree Creative Agency offering comprehensive services in Web Design, Website Development, Website Support and Maintenance, Cloud Services, Digital Marketing, SEO, PPC, Social Media and Content Marketing. Need a website from scratch or rebrand? Let’s connect.",
          icons: [
            {
              name: "Cloud Services",
              img: Icon.cloudService,
              imgTag: "Website development company",
            },
            {
              name: "Website",
              img: Icon.website,
              imgTag: "Website development company",
            },
            {
              name: "SEO",
              img: Icon.seo,
              imgTag: "Website development company",
            },
            {
              name: "Social media Markiting",
              img: Icon.smo,
              imgTag: "Website development company",
            },
            {
              name: "E-mail Markiting",
              img: Icon.emailMarketing,
              imgTag: "Website development company",
            },
            {
              name: "WhatsApp Markiting",
              img: Icon.whatsappMarketing,
              imgTag: "Website development company",
            },
          ]
        }
      ],
      casestudies:[
        {
          title: "Case Study 1",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          img: "case-studies-1.png",
          imgTag: "Website development company",
          url: "/",
        },
        {
          title: "Case Study 2",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          img: "case-studies-2.png",
          imgTag: "Website development company",
          url: "/",
        },
        {
          title: "Case Study 3",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          img: "case-studies-3.png",
          imgTag: "Website development company",
          url: "/",
        },
        {
          title: "Case Study 4",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          img: "case-studies-4.png",
          imgTag: "Website development company",
          url: "/",
        }
      ],
      team:[
        
        {
          name: "Deepika Jangra",
          designation: "Manager",
          description: "Hey, My team calls me the most dedicated person of coding Nectar and you just",
          dialog: "'Don't Underestimate The Power Of A Common Women'",
          img: "deepika.jpg",
          imgTag: "Website development company",
          socialMedia: {
            facebook: "www.facebook.com",
            instagram: "www.instagram.com",
            twitter: "www.twitter.com",
            linkedIn: "www.linkedin.com"
          }
        },
        {
          name: "Ajay Jangra",
          designation: "Team Head",
          description: "Hello, You should call me Sir as I'm born ready, kyunki",
          dialog: " 'Hum Bhi Woh Hai Jo Kabhi Kisi Ke Piche Khade Nahin Hote, Jha Khade Hote Hai Line Wahi Se Shuru Hoti H' ",
          img: "ajay.jpg",
          imgTag: "Website development company",
          socialMedia: {
            facebook: "www.facebook.com",
            instagram: "www.instagram.com",
            twitter: "www.twitter.com",
            linkedIn: "www.linkedin.com"
          }
        },
        {
          name: "Bir Yadav",
          designation: "SEO Expert",
          description: "Hey i am Bir, SEO is tough for many but smooth for me as ",
          dialog: "'Har Industry Ka Ek Gunda Hota H Or Yha Ka Gunda Hum Hai'",
          img: "bir.jpg",
          imgTag: "Website development company",
          socialMedia: {
            facebook: "www.facebook.com",
            instagram: "www.instagram.com",
            linkedIn: "www.linkedin.com"
          }
        },
        {
          name: "Susheel Kumar",
          designation: "React Developer",
          description: "Hi i am SJ. when they come with web developmet queries i just say ",
          dialog: "'Hum Karte Hain Kuch Prabhand Ap Chinta Mat Kriye'",
          img: "susheel.jpg",
          imgTag: "Website development company",
          socialMedia: {
            facebook: "www.facebook.com",
            instagram: "www.instagram.com",
            linkedIn: "www.linkedin.com"
          }
        },
        {
          name: "Priyanka Panchal",
          designation: "Backend Developer",
          description: "Hey you can call me Goona. But plz dont call me coz backend and frontend krte krte ",
          dialog: "'Haye Haye Main Toh Thakkkk Gayi'",
          img: "pari.jpg",
          imgTag: "Website development company",
          socialMedia: {
            facebook: "www.facebook.com",
            instagram: "www.instagram.com",
            linkedIn: "www.linkedin.com"
          }
        },
        // {
        //   name: "Rajni Chauhan",
        //   designation: "Graphic Designer",
        //   description: "Design friendly Hello You can call me RC. My designs  can blast your mind  but  for that....",
        //   dialog: "'Don't Angry Me'",
        //   img: "rajni.jpg",
        //   imgTag: "Website development company",
        //   socialMedia: {
        //     facebook: "www.facebook.com",
        //     instagram: "www.instagram.com",
        //     linkedIn: "www.linkedin.com"
        //   }
        // },
        {
          name: "Nitin Tanwar",
          designation: "Front-end Developer",
          description: "Hello, I am Nitin handling Front-end.... So come to me one by one as ",
          dialog: "'Meri To Sahab Kam GB Ki RAM Hai, Main Jyada Heavy File Upload Nahi Kar Pata'",
          img: "nitin.jpg",
          imgTag: "Website development company",
          socialMedia: {
            facebook: "www.facebook.com",
            instagram: "www.instagram.com",
            linkedIn: "www.linkedin.com"
          }
        }
      ],
      faq: [
        {
          question: "What Isy Ahgency?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id tellus. Pellentesque pretium bibendum velit, at interdum libero cursus sit amet. Fusce ac faucibus elit. Maecenas at tortor odio. Proin eu imperdiet risus, non tempor lorem.",
        },
        {
          question: "What Is Agency?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id tellus. Pellentesque pretium bibendum velit, at interdum libero cursus sit amet. Fusce ac faucibus elit. Maecenas at tortor odio. Proin eu imperdiet risus, non tempor lorem.",
        },
        {
          question: "What Is Agency?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id tellus. Pellentesque pretium bibendum velit, at interdum libero cursus sit amet. Fusce ac faucibus elit. Maecenas at tortor odio. Proin eu imperdiet risus, non tempor lorem.",
        },
        {
          question: "What Is Agency?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id tellus. Pellentesque pretium bibendum velit, at interdum libero cursus sit amet. Fusce ac faucibus elit. Maecenas at tortor odio. Proin eu imperdiet risus, non tempor lorem.",
        }
      ],
      blogs: [
        {
          title : "Digital Marketing Agency Pricing Guide",
          img : "blog1.png",
          imgTag: "Website development company",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id",
        },
        {
          title : "Digital Marketing Agency Pricing Guide",
          img : "blog1.png",
          imgTag: "Website development company",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id",
        },
        {
          title : "Digital Marketing Agency Pricing Guide",
          img : "blog1.png",
          imgTag: "Website development company",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id",
        },
        {
          title : "Digital Marketing Agency Pricing Guide",
          img : "blog1.png",
          imgTag: "Website development company",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id",
        },
        {
          title : "Digital Marketing Agency Pricing Guide",
          img : "blog1.png",
          imgTag: "Website development company",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mi sem, tristique vel blandit sed, varius id",
        },
      ],
      testimonial: [
        {
          title : "Best agency!",
          name: "Mr. Mohit Raheja ",
          designation: "Founder (Rentobees)",
          img : "mohit.jpg",
          imgTag: "Website development company",
          description: "Extremely professional team full of innovative ideas and creativity. They deliver what they commit. We are proud of Coding Nectar team and enjoyed working with them on our project.",
        },
        {
          title : "Loved it!",
          name: "Mrs. Bharti Bhatti",
          designation: "Director (BIDL)",
          img : "bidlTesti.jpg",
          imgTag: "Website development company",
          description: "I found Coding Nectar for my website and they understand the need of client and improvise accordingly. Their availability is so satisfying. Working with them was such a great experience. Definitely recommending it if anyone want to connect. Thank you.",
        },
        {
          title : "Great marketing agency!",
          name: "Mr. Sachin Mor",
          designation: "CEO (Rentobees)",
          img : "sachin.jpg",
          imgTag: "Website development company",
          description: "Glad to connect with Coding Nectar. Whole team is expert at their work that fulfil my demands. Their technique should be appriciated. Their team support and balanced mindset with positive solutions is amazing. I would like to recommend this trusted agency to all",
        },
        {
          title : "Great Experience!",
          name: "Mr. Yash Parsh",
          designation: "Founder & CEO",
          img : "yash.jpg",
          imgTag: "Website development company",
          description: "I recently worked with Coding Nectar. Their Choice and creativity is really impressive. They really pay attention towards the minor details that enhance the overall look of website. They stand out on the commitements. I am really impress with the services. Recommending you all this is worth connecting with them",
        },
        {
          title : "Best agency!",
          name: "Miss. Sanjana",
          designation: "Entrepreneur",
          img : "sanjana.jpg",
          imgTag: "Website development company",
          description: "I connected Coding Nectar form my website and totally trusted one. Whole team is very creative and attentive to their work. Specially their guidence for the process and technical areas make it worth. Highly recommend for the professional digital marketing work done. Thank you Coding Nectar.",
        },
        {
          title : "Satisfied!",
          name: "Mr. Motipal",
          designation: "CEO",
          img : "motipal.jpg",
          imgTag: "Website development company",
          description: "Coding Nectar was a game changer for me, their work exceeded our expectations. Highly recommended and want to work with them in future. Thankyou.",
        },
        
      ],
      aboutVisionMission: [
        {
          title: "Our Mission",
          description: "<p>At Coding Nectar, our mission is simple, to turn boring and not-so-performing digital tools into two-way innovative everlasting experiences. We believe in, <span>“Koi Dhandha Chota Ya Bada Nahi Hota, Agar Apka Dhandha Dauda, To Hamara Bhi Daudega.”</span> And we are working to make it happen with our expertise, innovation, and experience. For our trusted clients, we handle the complexities of the digital world while you focus on what you do best.</p>",
          img: 'mission.png',
          imgTag: "Website development company",
        },
        {
          title: "Our Vision",
          description: "<p>Here at Coding Nectar, our team works with a single aim in mind, to envision a world where every brand has a powerful online presence that performs. We create a blockbuster digital story together on a journey filled with creativity and a dash of drama. Say <span>'All Is Well'</span> when you have the right team to achieve digital excellence and create something truly spectacular.</p>",
          img: 'visiontwo.png',
          imgTag: "Website development company",
        },
      ],
      aboutsection4:[
        {
          title: "Creative Excellence",
          content: "Our web design and development services create visually appealing and user-friendly websites that engage your audience and strengthen your brand identity.",
          img: Icon.CreativeExcellence,
          imgTag: "Website development company",
        },
        {
          title: "Data-Driven Approach",
          content: "Our SEO strategies are backed by insights and trends to ensure your marketing efforts are effective and efficient.",
          img: Icon.DataDriven,
          imgTag: "Website development company",
        },
        {
          title: "Proven Track Record",
          content: "With a portfolio of successful projects and satisfied clients, we have a history of delivering high-quality work that exceeds expectations.",
          img: Icon.ProvenTrackRecord,
          imgTag: "Website development company",
        },
        {
          title: "Client-Centric Approach",
          content: "Your success is our success, and we work closely with you every step of the way to ensure your vision is met and a strong relationship is built.",
          img: Icon.ClientCentric,
          imgTag: "Website development company",
        },
      ],
      GetToKnowUs: [
        {
            home: {
                img: "WhyCodingNectar.png",
                imgTag: "Website development company",
                subTitle: "WHY CHOOSE US",
                title: "Why Coding Nectar?",
                description: "<p>As a Website Development and Digital Marketing Company, we are insanely in love with what we are doing. Our work is proven when the client achieves organic exposure, increases audience interaction/ traffic, and boosts conversion rate.</p>",
                url: '/',
                col: [
                    {
                        title: "Fueling Digital Innovations",
                        description: "A whole new website or redesigning what you have, get web solutions that are modern, functional, and digital success tools.",
                        img: Icon.FuelingDigital,
                        imgTag: "Website development company"
                    },
                    {
                        title: "Brand-Centric Solutions",
                        description: "Awesome visuals spotlighting your brand identity that is highly–personalized to your audience and business.",
                        img: Icon.BrandCentric,
                        imgTag: "Website development company"
                    },
                    {
                        title: "Revolutionize Your Engagement",
                        description: "High-converting digital marketing practices apt to the updated algorithms and trends to make your brand roar on search engines.",
                        img: Icon.Revolutionize,
                        imgTag: "Website development company"
                    }
                ],
                btnName: "Quick Web Meet"
            }
        },
        {
            about: {
                img: "WhoWeAre.png",
                imgTag: "Website development company",
                subTitle: "Who Are We?",
                title: "Your Crazy Creatives Heads",
                description: '<p><span>“Kehte Hain Agar Kisi Cheez Ko Dil Se Chaho Toh Puri Kainat Use Tumse Milane Ki Koshish Mein Lag Jaati Hai”</span>, and here we met at Coding Nectar, your best website design and development company in India, you were searching for. A group of passionate designers, developers, and marketing geeks, working together like the perfect Bollywood cast.</p><p>Just like the iconic line <span> "Yeh Dosti Hum Nahi Todenge"</span>, we are committed to carrying long-lasting relationships with our clients. As a top-notch Creative Agency in India, we dont just create websites and design graphics; we build digital experiences that leave a lasting experience.</p><p>Whether its website design, development, graphic design, social media marketing, Search Engine Optimisation, PPC, or content writing, we approach every project with the same intention: <span> “Digital World Sirf Teen Cheezon Ki Wajah Se Chalta Hai, Engagement, Engagement, Engagement. Aur Hum Engagement Ke Sath Conversions Bhi Dege.”</span></p>',
                url: '/',
                col: [
                    {
                        title: "End-to-End Solutions",
                        description: "A comprehensive suite of services under one roof!",
                        img: Icon.EndToEnd,
                        imgTag: "Digital Marketing Company"
                    },
                    {
                        title: "Fast Turnaround",
                        description: "Able to deliver projects on time without compromising on quality.",
                        img: Icon.FastTurnaround,
                        imgTag: "Digital Marketing Company"
                    }
                ],
                btnName: "To Phir Shuru Kare!"
            }
        }
      ],
      portfolio: [
        {
          name: 'RentoBees',
          img: 'CoverRentobees.jpg',
          mobile: 'rentobessMob.png',
          imgWebsite: 'Rentobees.jpg',
          clientLogo:'rentobeesLogo.png',
          imgTag:  'Website development company',
          url: "https://www.rentobees.com/"
        },
        {
          name: 'BIDL',
          img: 'CoverBidl.jpg',
          mobile: 'bidlMob.png',
          imgWebsite: 'BIDL.jpg',
          clientLogo: 'BIDLlogo.png',
          imgTag:  'Website development company',
          url: "https://bidl.in/"
        },
        {
          name: 'sanjana Jewellery Box',
          img: 'CoverSanjana.jpg',
          mobile: 'sanjanaMob.jpg',
          imgWebsite: 'sanjanasjewellerybox.jpg',
          clientLogo: 'sanjanaLogo.png',
          imgTag:  'Website development company',
          url: "https://sanjanasjewellerybox.com/"
        },
        {
          name: 'Estate Beyond',
          img: 'CoverEstate.jpg',
          mobile: 'beyondMob.jpg',
          imgWebsite: 'beyondestate.jpg',
          clientLogo: 'beyond-logo.png',
          imgTag:  'Website development company',
          url: "https://www.estatebeyond.com/"
        },
        {
          name: 'Empire Geotech',
          img: 'coverempiregeotech.jpg',
          mobile: 'mobgeotech.jpg',
          imgWebsite: 'empiire.jpg',
          clientLogo: 'empirelogo.png',
          imgTag:  'Website development company',
          url: "https://empiregeotech.com/"
        },
        
      ],
      homeweserve: [
        {
          name:  "Website Development",
          icon: Icon.webDevelopment,
          url: genrateURL("Website Development"),
          shortDescription: "Website Design | Web Development | E-Commerce Solutions | UI/UX Design",
        },
        {
          name:  "Graphic Design",
          icon: Icon.GraphicIcon,
          url: genrateURL("Graphic Design"),
          shortDescription: "Brand Identity | Illustration | User Interface Design | Brand Building",
        },
        {
          name:  "Search Engine Optimization",
          icon: Icon.SeoIcon,
          url: genrateURL("Search Engine Optimization"),
          shortDescription: "On-Page Optimization | Off-Page Optimization | Technical SEO | Analytics & Reporting",
        },
        {
          name:  "Social Media Marketing",
          icon: Icon.SMMIcon,
          url: genrateURL("Social Media Marketing"),
          shortDescription: "Facebook | Instagram | LinkedIn | YouTube",
        },
        {
          name:  "Pay Per Click",
          icon: Icon.PPCIcon,
          url: genrateURL("Pay Per Click"),
          shortDescription: "Google Ads Management | Google Search Ads | Conversion Tracking & Optimization",
        },
        {
          name:  "Content Marketing",
          icon: Icon.ContentMarketingIcon,
          url: genrateURL("Content Marketing"),
          shortDescription: "SEO Content Writing | Website Content | Social Media Content | Blog Writing",
        },
      ],
      footerquicklinks: [
        {
          name:  "Website Development",
          url: genrateURL("Website Development"),
        },
        {
          name: "Graphic Design",
          url: genrateURL("Graphic Design"),
        },
        {
          name: "Search Engine Optimization",
          url: genrateURL("Search Engine Optimization"),
        },
        {
          name: "Social Media Marketing",
          url: genrateURL("Social Media Marketing"),
        },
        {
          name: "Pay Per Click",
          url: genrateURL("Pay Per Click"),
        },
        {
          name: "Content Marketing",
          url: genrateURL("Content Marketing"),
        },
      ],
}